<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapEuropeanUnionActsItems"
    :api="{
      moduleId: 'pk',
      modulesWithName: 'custom_query_european_union_acts_with_names',
      modules: 'module_european_union_acts_header',
      favoriteHated: 'favorite_and_hated_european_union_acts_header',
      favoriteHatedResponse: 'favorite_and_hateful_european_union_acts_list',
      bookmarks: 'bookmarks/fetchEuropeanUnionActs',
    }"
    #default="{items}"
  >
    <european-union-acts-list :items="items" />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import EuropeanUnionActsList from '../../EuropeanUnionActs/EuropeanUnionActsList';
import { mapEuropeanUnionActsItems } from '../../EuropeanUnionActs/mapEuropeanUnionActsItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    EuropeanUnionActsList,
  },
  metaInfo() {
    return {
      title: 'Monitoring prawa Unii Europejskiej',
    };
  },
  data() {
    return {
      mapEuropeanUnionActsItems,
    };
  },
};
</script>
