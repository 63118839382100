var render = function render(){var _vm=this,_c=_vm._self._c;return _c('monitoring-items',{attrs:{"activeModules":_vm.activeModules,"mapItems":_vm.mapEuropeanUnionActsItems,"api":{
    moduleId: 'pk',
    modulesWithName: 'custom_query_european_union_acts_with_names',
    modules: 'module_european_union_acts_header',
    favoriteHated: 'favorite_and_hated_european_union_acts_header',
    favoriteHatedResponse: 'favorite_and_hateful_european_union_acts_list',
    bookmarks: 'bookmarks/fetchEuropeanUnionActs',
  }},scopedSlots:_vm._u([{key:"default",fn:function({items}){return [_c('european-union-acts-list',{attrs:{"items":items}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }